import React, {useState, useEffect} from 'react';
import axios from 'axios';
import { DataGrid, bgBG, roRO, GridColDef, GridToolbarContainer, GridToolbarExport, GridRowParams, GridValueGetterParams } from '@mui/x-data-grid';
import { Box, Container } from '@mui/system';
import 'react-data-grid/lib/styles.css';
import CircularProgress from '@mui/material/CircularProgress';
import '../Grid.css';
import Divider from '@mui/material/Divider';
import SyncOutlinedIcon from '@mui/icons-material/SyncOutlined';
import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ButtonGroup from '@mui/material/ButtonGroup';
import Button from '@mui/material/Button';
import SendIcon from '@mui/icons-material/Send';
import LinearProgress from '@mui/material/LinearProgress';
import * as ReactDOM from 'react-dom';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import configData from "../config/config.json";
import Skeleton from '@mui/material/Skeleton';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Chip from '@mui/material/Chip';
import HourglassBottomIcon from '@mui/icons-material/HourglassBottom';
import { red, yellow, orange } from '@mui/material/colors';
import NumbersIcon from '@mui/icons-material/Numbers';
import TextField from '@mui/material/TextField';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import i18next from "i18next";
import common_en from "../translations/en/common.json";
import common_bg from "../translations/bg/common.json";
import common_ro from "../translations/ro/common.json";
import { initReactI18next, useTranslation } from "react-i18next";
import Collapse from '@mui/material/Collapse';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { Dataset } from '@mui/icons-material';
import * as XLSX from 'xlsx';
import * as XMLParser from 'react-xml-parser';
import * as md5 from 'md5'

const resources = {
  en: {
    common: common_en               // 'common' is our custom namespace
  },
  bg: {
    common: common_bg
  },
  ro: {
    common: common_ro
}
}

function gridTranslations(language) {
  switch(language) {
    case 'bg':
      console.log('case bg')
      return bgBG.components.MuiDataGrid.defaultProps.localeText
    case 'en':
      console.log('case en')
      return bgBG.components.MuiDataGrid.defaultProps.localeText
    case 'ro':
      console.log('case ro')
      return roRO.components.MuiDataGrid.defaultProps.localeText
    default:
      console.log('case default')
      return bgBG.components.MuiDataGrid.defaultProps.localeText
  }
}

const gridLocalization = gridTranslations(configData.MAIN_CONFIGURATIONS.LANGUAGE)

console.log(configData.MAIN_CONFIGURATIONS.LANGUAGE)

i18next
.use(initReactI18next)
.init({resources, lng: configData.MAIN_CONFIGURATIONS.LANGUAGE});

export default function OrdersAll(props) {

    const { t, i18n } = useTranslation('common');

    var currentDate = new Date();
    var today = currentDate.getFullYear() + '-' + (currentDate.getMonth() + 1) + '-' + currentDate.getDate()
    var firstname = sessionStorage.getItem('firstname');
    var lastname = sessionStorage.getItem('lastname');
    var userMail = sessionStorage.getItem('username'); 
    var currentUsername = firstname +" "+lastname
    var currentUserEmail = userMail

    var for7Days = new Date(Date.now() - 7 * 24 * 60 * 60 * 1000)  
    var sevenDays = for7Days.getFullYear() + '-' + (for7Days.getMonth() + 1) + '-' + for7Days.getDate()

    var for30Days = new Date(Date.now() - 30 * 24 * 60 * 60 * 1000)  
    var thirtyDays = for30Days.getFullYear() + '-' + (for30Days.getMonth() + 1) + '-' + for30Days.getDate()

    const [isLoading, setLoading] = useState(true);
    const [period, setPeriod] = useState(today);
    const [skeleton, setSkeleton] = useState(false);
    const [items, setItems] = useState();
    const [selectionModel, setSelectionModel] = React.useState([]);
    const [openAddComent, setOpenAddComent] = React.useState(false);
    const [messages, setMessages] = useState({});
    const [checkedVisible, setCheckedVisible] = React.useState(true);
    const [checkedNotify, setCheckedNotify] = React.useState(true);
    const [valueComment, setValue] = React.useState('');
    const [openAlert, setOpenAlert] = React.useState(false);
    const [alert, setAlert] = React.useState(true);
    const [isLoadingData, setIsLoadingData] = React.useState(false);
    const [username, setUsername] = useState(currentUsername);
    const [userEmail, setUserEmail] = useState(currentUserEmail);
    const [orderStatus, setOrderStatus] = React.useState('processing');

    const handleChangeNotify = (event: React.ChangeEvent<HTMLInputElement>) => {
      setCheckedNotify(event.target.checked);
    };

    const handleChangeVisible = (event: React.ChangeEvent<HTMLInputElement>) => {
      setCheckedVisible(event.target.checked);
    };

    const handleChangeComment = (event: React.ChangeEvent<HTMLInputElement>) => {
      setValue(event.target.value);
    };

    const handleChangeStatus = (event: SelectChangeEvent) => {
      setOrderStatus(event.target.value);
    };
    
    const handleClickOpen = () => {
      setOpenAddComent(true);
    };
  
    const handleClose = () => {
      setOpenAddComent(false);
    };

    //Copytoclipboard function
    const copyToClipboar = () => {
      var text = ''

      for(var i = 0; i < selectionModel.length; i++) {
          const before_ = selectionModel[i].name.substring(0, selectionModel[i].name.indexOf('_'));
          var clear_date = new Date(selectionModel[i].created_at).toJSON().slice(0,10).replace(/-/g,'/');
          text += ';'+clear_date+';'+before_+';'+selectionModel[i].increment_id+';'+selectionModel[i].branch.replace(" / ",";")+';'+selectionModel[i].name+';'+selectionModel[i].qty+';;;;;;;'+selectionModel[i].price+'\n'
      }

      return text;
    }

     //Generate DRP excel file ()=>this.generateDRPFile
    const generateDRPFile = () => {
      const dataSetProducts = []
      var mar = ""
      var kh_prod = ""

      function generateRow(mar, kh_code, product) {
        return {"REFERENCE NO.":product, "GRU_T_KOD":"","NAZ":"","WSK_CEN":"T", "MAR":mar, "PU":1,"RZB_KOD":"OS","STATUS1":"","STATUS2":"","MAGA":"","PODMAGA":"","NAZ_P":"","TOW_KOD":"","UWAGI":"","ABC_Z":"","ABC_S":"","ABC_M":"","KH_PROD":kh_code,"KH_DOST":"","JM_KOD":"stz","STS":"","VAT_KOD":"","OSO_KOD":"","STA":"T","NR_KAT":"","OSO":"","PRI":"","PCN_KOD_":"","NAZ_EXP":"","NAZ_CELN":"","KPL":"","KROT":"","KRAJ":"","WAGA_N":"","WAGA_B":"","ODPADY":""}
      }

      function getCategoryAndName(porudct) {

        var catalog = "YQBW2"
        var request = "GetOEMPartApplicability:Catalog="+catalog+"|OEM="+porudct+"|ssd=$*KwE1ARB1dE5wcUpNbkwSCW15WV5ANDIzM2lgezYzf3ZibmhgMjUMfCRsaWMkOzZpY3w2KiN0ZCQ7IWYRfTYkLSJxb28hZD45a2p8AAAAALT7XO0=$|Locale=en_US"
        var key = "yq796580"
        var md5_request = md5(request+key)

        var request_body = '<soap:Envelope xmlns:soap="http://www.w3.org/2003/05/soap-envelope"><soap:Body><QueryDataLogin xmlns="http://WebCatalog.Kito.ec"><request>'+request+'</request><login>'+key+'</login><hmac>'+md5_request+'</hmac></QueryDataLogin></soap:Body></soap:Envelope>'

        var product_body = {"catalog":catalog, "product":porudct}
        
        const getInformation = axios.post(configData.MAGENTO_APIS.PRODUCT_INFORMATION_API, product_body);

                axios.all([getInformation]).then(axios.spread(function(res1) {
                  var response = res1
                  var xml = new XMLParser().parseFromString(response);
                  console.log(xml);
                  console.log(xml.getElementsByTagName('Unit'));
                  console.log(xml.getElementsByTagName('Detail'));
                  console.log(response)})).catch( error => {console.log(error)})
      }
      

      var brands = ['VAG_', 'DACIA/RENAULT_', 'ALFA-ROMEO_','AUDI_','BENTLEY_','BMW_','CHEVROLET_','CHRYSLER_','CITROEN_','DODGE_','DACIA_','DAEWOO_','DAIHATSU_','FIAT_','FORD_','GENERAL_MOTORS_','GMC_','HONDA_','HYUNDAI_','INFINITI_','ISUZU_','JAGUAR_','JEEP_','KIA_','LAMBORGHINI_','LANCIA_','LEXUS_','LAND_ROVER_','MAYBACH_','MAZDA_','MERCEDES-BENZ_','MB_A_','MINI_','MITSUBISHI_','NISSAN_','OPEL_','PEUGEOT_','PORSCHE_','RENAULT_','ROLLS-ROYCE_','ROVER_','SAAB_','SEAT_','SUBARU_','SUZUKI_','SMART_','TOYOTA_','VAUXHALL_','VOLKSWAGEN_','VOLVO_','YAMAHA_']

      for(var i = 0; i < selectionModel.length; i++) {

        var clearNumber = selectionModel[i].name;
        var productBrand = clearNumber.substr(0, clearNumber.indexOf('_'))

        for(var y = 0; y < brands.length; y++){
          clearNumber = clearNumber.replace(brands[y],'')
          }
        
        var product_name = clearNumber.replace(/_/g,' ');
        var product_name_without_space = clearNumber.replace(/_/g,'');

        getCategoryAndName(product_name_without_space)

        if(productBrand == "BMW"){
          //dataSetProducts.push({"REFERENCE NO.":product_name, "GRU_T_KOD":"","NAZ":"","WSK_CEN":"T", "MAR":"05", "PU":1,"RZB_KOD":"OS","STATUS1":"","STATUS2":"","MAGA":"","PODMAGA":"","NAZ_P":"","TOW_KOD":"","UWAGI":"","ABC_Z":"","ABC_S":"","ABC_M":"","KH_PROD":"019935","KH_DOST":"","JM_KOD":"stz","STA":"T"})
          dataSetProducts.push(generateRow("05","019935", product_name))
        }
        else if(productBrand == "FORD"){
            dataSetProducts.push(generateRow("03","002632", product_name))
          }
        else if(productBrand == "MB"){
            dataSetProducts.push(generateRow("02","016240", product_name))
        }
        else if(productBrand == "KIA"){
          dataSetProducts.push(generateRow("53","002636", product_name))
          }
        else if(productBrand == "TOYOTA"){
          dataSetProducts.push(generateRow("19","019927", product_name))
        }
        else if(productBrand == "PEUGEOT"){
          dataSetProducts.push(generateRow("08","019923", product_name))
        }
        else if(productBrand == "PORSCHE"){
          dataSetProducts.push(generateRow("52","847058", product_name))
        }
        else if(productBrand == "DACIA/RENAULT"){
          dataSetProducts.push(generateRow("09","019924", product_name))
        }
        else if(productBrand == "HYUNDAI"){
          dataSetProducts.push(generateRow("20","002634", product_name))
        }
        else if(productBrand == "FIAT"){
          dataSetProducts.push(generateRow("07","002631", product_name))
        }
        else if(productBrand == "CITROEN"){
          dataSetProducts.push(generateRow("21","002735", product_name))
        }
        else if(productBrand == "OPEL"){
          dataSetProducts.push(generateRow("04","019928", product_name))
        }
        else if(productBrand == "NISSAN"){
          dataSetProducts.push(generateRow("16","019936", product_name))
        }
        else if(productBrand == "MAZDA"){
          dataSetProducts.push(generateRow("14","019937", product_name))
        }
        else if(productBrand == "VOLVO"){
          dataSetProducts.push(generateRow("24","019939", product_name))
        }
        else if(productBrand == "MITSUBISHI"){
          dataSetProducts.push(generateRow("15","030296", product_name))
        }
        else if(productBrand == "SUBARU"){
          dataSetProducts.push(generateRow("17","078508", product_name))
        }
        else if(productBrand == "SUZUKI"){
          dataSetProducts.push(generateRow("18","078509", product_name))
        }
        else if(productBrand == "JAGUAR"){
          dataSetProducts.push(generateRow("744","863051", product_name))
        }
        else if(productBrand == "JAGUAR"){
          dataSetProducts.push(generateRow("744","863051", product_name))
        }
        else if(productBrand == "VAG"){
          dataSetProducts.push(generateRow("01","019929", product_name))
        }
        else {
          dataSetProducts.push(generateRow("","", product_name))
        }

        //dataSetProducts.push({"item":clearNumber, "WSK_CEN":"T", "MAR":mar, "PU":1,"RZB_KOD":"OS","KH_PROD":kh_prod,"JM_KOD":"stz","STA":"T"})

        //mar = ""
        //kh_prod = ""

      }

      console.log(dataSetProducts)

      const worksheet = XLSX.utils.json_to_sheet(dataSetProducts);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
      //let buffer = XLSX.write(workbook, { bookType: "xlsx", type: "buffer" });
      //XLSX.write(workbook, { bookType: "xlsx", type: "binary" });
      XLSX.writeFile(workbook, "DataSheet.xlsx");

    }

    //function to generate custom toolbar in the grid with Export and Copy to clipboard options
    function CustomToolbar() {
      return (
            <GridToolbarContainer>
                <Stack direction="row" spacing={1}>
                  <GridToolbarExport />
                  {configData.MAIN_CONFIGURATIONS.COPY_TO_CLIPBOARD ? <Button size="small" onClick={() =>  navigator.clipboard.writeText(copyToClipboar())} startIcon={<ContentCopyIcon />}>Copy to clipboard</Button>: ""}
                  {configData.MAIN_CONFIGURATIONS.DRP_EXPORT ? <Button size="small" onClick={() => generateDRPFile()} startIcon={<ContentCopyIcon />}>DRP Export</Button>: ""}
              </Stack>
            </GridToolbarContainer>
          );
        }

    useEffect(() => {

      var messagesBody = { "item_id": "1" };

      var indents = [];
      var commentsData = [];

      const getItemsAll = axios.get("https://dev-api.dashboard.vip-intercars.bg/orders/"+period+"/all");
      const getMessages = axios.post(configData.MAGENTO_APIS.MAGENTO_ORDER_MESSAGES, messagesBody);
      const getWaitingOrders = axios.get(configData.MAGENTO_APIS.MAGENTO_ORDER_WAITING);

      axios.all([getItemsAll, getMessages, getWaitingOrders]).then(axios.spread(function(res1, res2, res3) {

        var response = res1;
        var response2 = res2;
        var response3 = res3;
        
        for (var i = 0; i < response.data.items.length; i++) {
          for( var y = 0; y < response.data.items[i].items.length; y++){
              var product_name = response.data.items[i].items[y].name;
              var product_brand = product_name.substr(0, product_name.indexOf('_'))
              var delay = calcDeliveryTime(product_brand, response.data.items[i].created_at)
              var order_id = response.data.items[i].increment_id

              if(response.data.items[i].extension_attributes.is_waiting_for_approval) {
                var waitingForApproval = { "increment_id": response.data.items[i].increment_id, "date": response.data.items[i].updated_at};

                const getMessages = axios.post(configData.MAGENTO_APIS.MAGENTO_ORDER_WAITING_ADD, waitingForApproval);

                axios.all([getMessages]).then(axios.spread(function(res1) {
                  var response = res1 
                  console.log(response)})).catch( error => {console.log(error)})
              }

              var messagesData = []
              var approvalInformation = []
              var defaultWaitingStatus = {}


                for(var v = 0; v < response3.data.items.length; v++) {
                  //console.log(response3.data.items[v].increment_id.toString()+" / "+order_id.toString())
                  if((response3.data.items[v].increment_id.toString() == order_id.toString()) && (response.data.items[i].extension_attributes.is_waiting_for_approval == false)){
                    approvalInformation.push({"approved":"yes", "date":response3.data.items[v].date})
                  }
                }

                if(Object.keys(approvalInformation).length === 0) {
                  approvalInformation.push({"approved":"no", "date":response.data.items[i].updated_at})
                }

              for(var z = 0; z < response2.data.items.length; z++) {
                if(response2.data.items[z].item_id == response.data.items[i].items[y].item_id){
                  messagesData.push({"item_id":response2.data.items[z].item_id, "type":response2.data.items[z].type, "message":response2.data.items[z].message})
                }
              }
              
              indents.push({"id":response.data.items[i].items[y].item_id,"external_id":response.data.items[i].items[y].external_id,"entity_id":response.data.items[i].entity_id,"increment_id":response.data.items[i].increment_id,"waiting_for_approval":response.data.items[i].extension_attributes.is_waiting_for_approval,"entity_id":response.data.items[i].entity_id,"created_at":response.data.items[i].created_at,"delay":delay,"status":response.data.items[i].status, "status_histories":response.data.items[i].status_histories, "branch":response.data.items[i].extension_attributes.branch+" / "+response.data.items[i].extension_attributes.kh_code,"name":response.data.items[i].items[y].name,"brand":product_brand,"qty":response.data.items[i].items[y].qty_ordered,"price":response.data.items[i].items[y].price_incl_tax,"total_price":response.data.items[i].items[y].row_total_incl_tax,"order_id":response.data.items[i].items[y].order_id,"messages":messagesData, "approval_info":approvalInformation});
              
              approvalInformation = []
              messagesData = []
      
            }
        }

        console.log(indents)
        setItems(indents)
        setLoading(false)
      }));
    
      /*const getItems = axios.get("https://dev-api.dashboard.vip-intercars.bg/orders/"+period+"/all").then(response => {

      var indents = [];
      var commentsData = [];

        for (var i = 0; i < response.data.items.length; i++) {
            for( var y = 0; y < response.data.items[i].items.length; y++){
                var product_name = response.data.items[i].items[y].name;
                var product_brand = product_name.substr(0, product_name.indexOf('_'))
                var delay = calcDeliveryTime(product_brand, response.data.items[i].created_at)
                var order_id = response.data.items[i].increment_id

                indents.push({"id":response.data.items[i].items[y].item_id,"increment_id":response.data.items[i].increment_id,"entity_id":response.data.items[i].entity_id,"created_at":response.data.items[i].created_at,"delay":delay,"status":response.data.items[i].status, "status_histories":response.data.items[i].status_histories, "branch":response.data.items[i].extension_attributes.branch+" / "+response.data.items[i].extension_attributes.kh_code,"name":response.data.items[i].items[y].name,"brand":product_brand,"qty":response.data.items[i].items[y].qty_ordered,"price":response.data.items[i].items[y].price_incl_tax,"total_price":response.data.items[i].items[y].row_total_incl_tax,"order_id":response.data.items[i].items[y].order_id});
              
        }
      }*/

    }, []);
    
    var order_id = props.order_id;

    function trimBrand(product) {
      var brands = ['VAG_', 'DACIA/RENAULT_', 'ALFA-ROMEO_','AUDI_','BENTLEY_','BMW_','CHEVROLET_','CHRYSLER_','CITROEN_','DODGE_','DACIA_','DAEWOO_','DAIHATSU_','FIAT_','FORD_','GENERAL_MOTORS_','GMC_','HONDA_','HYUNDAI_','INFINITI_','ISUZU_','JAGUAR_','JEEP_','KIA_','LAMBORGHINI_','LANCIA_','LEXUS_','LAND_ROVER_','MAYBACH_','MAZDA_','MERCEDES-BENZ_','MB_A','MINI_','MITSUBISHI_','NISSAN_','OPEL_','PEUGEOT_','PORSCHE_','RENAULT_','ROLLS-ROYCE_','ROVER_','SAAB_','SEAT_','SUBARU_','SUZUKI_','SMART_','TOYOTA_','VAUXHALL_','VOLKSWAGEN_','VOLVO_','YAMAHA_']
      var clearNumber = product;
    
      for(var i = 0; i < brands.length; i++){
          clearNumber = clearNumber.replace(brands[i],'')
      }
    
      return clearNumber.replace(/_/g,'');
    }

    const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
      <Tooltip placement="right" {...props} classes={{ popper: className }} />
    ))(({ theme }) => ({
      [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: '#f5f5f9',
        color: 'rgba(0, 0, 0, 0.87)',
        maxWidth: 600,
        fontSize: theme.typography.pxToRem(6),
        border: '1px solid #dadde9',
      },
    }));

    const updateRecords = () => {

      setIsLoadingData(true)

      var timeDate = period;

      var messagesBody = { "item_id": "1" };

      var indents = [];
      var commentsData = [];

      //setSkeleton(true);

      const getItemsAll = axios.get(configData.MAGENTO_APIS.MAGENTO_ORDERS+timeDate+"/all");
      const getMessages = axios.post(configData.MAGENTO_APIS.MAGENTO_ORDER_MESSAGES, messagesBody);
      const getWaitingOrders = axios.get(configData.MAGENTO_APIS.MAGENTO_ORDER_WAITING);

      axios.all([getItemsAll, getMessages, getWaitingOrders]).then(axios.spread(function(res1, res2, res3) {

        var response = res1;
        var response2 = res2;
        var response3 = res3;
        
        for (var i = 0; i < response.data.items.length; i++) {
          for( var y = 0; y < response.data.items[i].items.length; y++){
              var product_name = response.data.items[i].items[y].name;
              var product_brand = product_name.substr(0, product_name.indexOf('_'))
              var delay = calcDeliveryTime(product_brand, response.data.items[i].created_at)
              var order_id = response.data.items[i].increment_id

              if(response.data.items[i].extension_attributes.is_waiting_for_approval) {
                var waitingForApproval = { "increment_id": response.data.items[i].increment_id, "date": response.data.items[i].updated_at};

                const getMessages = axios.post(configData.MAGENTO_APIS.MAGENTO_ORDER_WAITING_ADD, waitingForApproval);

                axios.all([getMessages]).then(axios.spread(function(res1) {
                  var response = res1 
                  console.log(response)})).catch( error => {console.log(error)})
              }

              var messagesData = []
              var approvalInformation = []
              var defaultWaitingStatus = {}

              for(var v = 0; v < response3.data.items.length; v++) {
                //console.log(response3.data.items[v].increment_id.toString()+" / "+order_id.toString())
                if((response3.data.items[v].increment_id.toString() == order_id.toString()) && (response.data.items[i].extension_attributes.is_waiting_for_approval == false)){
                  approvalInformation.push({"approved":"yes", "date":response3.data.items[v].date})
                }
              }

              if(Object.keys(approvalInformation).length === 0) {
                approvalInformation.push({"approved":"no", "date":response.data.items[i].updated_at})
              }

              for(var z = 0; z < response2.data.items.length; z++) {
                if(response2.data.items[z].item_id == response.data.items[i].items[y].item_id){
                  messagesData.push({"item_id":response2.data.items[z].item_id, "type":response2.data.items[z].type, "message":response2.data.items[z].message})
                }
              }
              
              indents.push({"id":response.data.items[i].items[y].item_id, "external_id":response.data.items[i].items[y].external_id,"entity_id":response.data.items[i].entity_id,"increment_id":response.data.items[i].increment_id,"waiting_for_approval":response.data.items[i].extension_attributes.is_waiting_for_approval,"entity_id":response.data.items[i].entity_id,"created_at":response.data.items[i].created_at,"delay":delay,"status":response.data.items[i].status, "status_histories":response.data.items[i].status_histories, "branch":response.data.items[i].extension_attributes.branch+" / "+response.data.items[i].extension_attributes.kh_code,"name":response.data.items[i].items[y].name,"brand":product_brand,"qty":response.data.items[i].items[y].qty_ordered,"price":response.data.items[i].items[y].price_incl_tax,"total_price":response.data.items[i].items[y].row_total_incl_tax,"order_id":response.data.items[i].items[y].order_id,"messages":messagesData, "approval_info":approvalInformation});
              
              approvalInformation = []
              messagesData = []
      
            }
        }

        console.log(indents)
        setItems(indents)
        setIsLoadingData(false)
        //setSkeleton(false)
      }));

    }

    const changePeriod = (time) => {

      var timeDate = ""

      var messagesBody = { "item_id": "1" };

      var indents = [];
      var commentsData = [];
        
      if(time === 0) { timeDate = today } 
      else if(time === 7) { timeDate = sevenDays } 
      else if(time === 30) { timeDate =thirtyDays }

      setPeriod(timeDate)

      setSkeleton(true);

      const getItemsAll = axios.get(configData.MAGENTO_APIS.MAGENTO_ORDERS+timeDate+"/all");
      const getMessages = axios.post(configData.MAGENTO_APIS.MAGENTO_ORDER_MESSAGES, messagesBody);
      const getWaitingOrders = axios.get(configData.MAGENTO_APIS.MAGENTO_ORDER_WAITING);

      axios.all([getItemsAll, getMessages, getWaitingOrders]).then(axios.spread(function(res1, res2, res3) {

        var response = res1;
        var response2 = res2;
        var response3 = res3;
        
        for (var i = 0; i < response.data.items.length; i++) {
          for( var y = 0; y < response.data.items[i].items.length; y++){
              var product_name = response.data.items[i].items[y].name;
              var product_brand = product_name.substr(0, product_name.indexOf('_'))
              var delay = calcDeliveryTime(product_brand, response.data.items[i].created_at)
              var order_id = response.data.items[i].increment_id

              if(response.data.items[i].extension_attributes.is_waiting_for_approval) {
                var waitingForApproval = { "increment_id": response.data.items[i].increment_id, "date": response.data.items[i].updated_at};

                const getMessages = axios.post(configData.MAGENTO_APIS.MAGENTO_ORDER_WAITING_ADD, waitingForApproval);

                axios.all([getMessages]).then(axios.spread(function(res1) {
                  var response = res1 
                  console.log(response)})).catch( error => {console.log(error)})
              }

              var messagesData = []
              var approvalInformation = []
              var defaultWaitingStatus = {}

              for(var v = 0; v < response3.data.items.length; v++) {
                //console.log(response3.data.items[v].increment_id.toString()+" / "+order_id.toString())
                if((response3.data.items[v].increment_id.toString() == order_id.toString()) && (response.data.items[i].extension_attributes.is_waiting_for_approval == false)){
                  approvalInformation.push({"approved":"yes", "date":response3.data.items[v].date})
                }
              }

              if(Object.keys(approvalInformation).length === 0) {
                approvalInformation.push({"approved":"no", "date":response.data.items[i].updated_at})
              }

              for(var z = 0; z < response2.data.items.length; z++) {
                if(response2.data.items[z].item_id == response.data.items[i].items[y].item_id){
                  messagesData.push({"item_id":response2.data.items[z].item_id, "type":response2.data.items[z].type, "message":response2.data.items[z].message})
                }
              }
              
              indents.push({"id":response.data.items[i].items[y].item_id,"external_id":response.data.items[i].items[y].external_id,"entity_id":response.data.items[i].entity_id,"increment_id":response.data.items[i].increment_id,"waiting_for_approval":response.data.items[i].extension_attributes.is_waiting_for_approval,"entity_id":response.data.items[i].entity_id,"created_at":response.data.items[i].created_at,"delay":delay,"status":response.data.items[i].status, "status_histories":response.data.items[i].status_histories, "branch":response.data.items[i].extension_attributes.branch+" / "+response.data.items[i].extension_attributes.kh_code,"name":response.data.items[i].items[y].name,"brand":product_brand,"qty":response.data.items[i].items[y].qty_ordered,"price":response.data.items[i].items[y].price_incl_tax,"total_price":response.data.items[i].items[y].row_total_incl_tax,"order_id":response.data.items[i].items[y].order_id,"messages":messagesData, "approval_info":approvalInformation});
              
              approvalInformation = []
              messagesData = []
      
            }
        }

        console.log(indents)
        setItems(indents)
        setSkeleton(false)
      }));

    }

    const orderManage = (status) => {

      const axiosPosts = []
      var arrayOrders = []

      function findUnique(arr, predicate) {
        var found = {};
        arr.forEach(d => {
          found[predicate(d)] = d;
        });
        return Object.keys(found).map(key => found[key]); 
      }

      for (let i = 0; i < selectionModel.length; i++) {
          arrayOrders.push({"entity_id": selectionModel[i].entity_id})
      }

      var uniqueOrders = findUnique(arrayOrders, d => d.entity_id);
  
      if(status === 1){

        for (let i = 0; i < uniqueOrders.length; i++) {

          const dataComment = { "entity_id": uniqueOrders[i].entity_id, "statusHistory": {"comment": "Артикулът е поръчан", "status": "processing", "entity_id": 0,"entity_name": "order","is_customer_notified": 1,"is_visible_on_front": 1,"parent_id": uniqueOrders[i].entity_id}};

          const postComment = axios.post(configData.MAGENTO_APIS.MAGENTO_COMMENTS, dataComment);

          axiosPosts.push(postComment)

        }
  
        axios.all([axiosPosts])
        .then(ReactDOM.render(<LinearProgress />, document.getElementById("alertContainer")))
        .then(axios.spread((...responses) => {
        ReactDOM.render(<Alert severity="success" sx={{ width: '98%' }}>
        Comment added and status changed.
        </Alert>, document.getElementById("alertContainer"));
        setTimeout(() => { updateRecords()}, 3000)
        }))
        .catch((err) => {
        console.log("AXIOS ERROR: ", err);
        ReactDOM.render(<Alert severity="error" sx={{ width: '98%' }}>
            <AlertTitle>Error</AlertTitle>
        {err.code} - {err.message}
        </Alert>, document.getElementById("alertContainer"));
        })
      }
      else if(status === 2){
        
        
        for (let i = 0; i < uniqueOrders.length; i++) {

          const dataComment = { "entity_id": uniqueOrders[i].entity_id, "statusHistory": {"comment": "Поръчката е изпълнена", "status": "complete", "entity_id": 0,"entity_name": "order","is_customer_notified": 1,"is_visible_on_front": 1,"parent_id": uniqueOrders[i].entity_id}};

          const postComment = axios.post(configData.MAGENTO_APIS.MAGENTO_COMMENTS, dataComment);

          axiosPosts.push(postComment)

        }
  
        axios.all([axiosPosts])
        .then(ReactDOM.render(<LinearProgress />, document.getElementById("alertContainer")))
        .then(axios.spread((...responses) => {
        ReactDOM.render(<Alert severity="success" sx={{ width: '98%' }}>
        Comment added and status changed.
        </Alert>, document.getElementById("alertContainer"));
        setTimeout(() => { updateRecords()}, 3000)
        }))
        .catch((err) => {
        console.log("AXIOS ERROR: ", err);
        ReactDOM.render(<Alert severity="error" sx={{ width: '98%' }}>
            <AlertTitle>Error</AlertTitle>
        {err.code} - {err.message}
        </Alert>, document.getElementById("alertContainer"));
        })
      }
      else if(status === 0){
        
        for (let i = 0; i < uniqueOrders.length; i++) {

          const dataComment = { "entity_id": uniqueOrders[i].entity_id, "statusHistory": {"comment": "Поръчката не може да бъде изпълнена", "status": "canceled", "entity_id": 0,"entity_name": "order","extension_attributes":{"added_by":username},"is_customer_notified": 1,"is_visible_on_front": 1,"parent_id": uniqueOrders[i].entity_id}};

          const postComment = axios.post(configData.MAGENTO_APIS.MAGENTO_COMMENTS, dataComment);

          axiosPosts.push(postComment)

        }
  
        axios.all([axiosPosts])
        .then(ReactDOM.render(<LinearProgress />, document.getElementById("alertContainer")))
        .then(axios.spread((...responses) => {
        ReactDOM.render(<Alert severity="success" sx={{ width: '98%' }}>
        Comment added and status changed.
        </Alert>, document.getElementById("alertContainer"));
        setTimeout(() => { updateRecords()}, 3000)
        }))
        .catch((err) => {
        console.log("AXIOS ERROR: ", err);
        ReactDOM.render(<Alert severity="error" sx={{ width: '98%' }}>
            <AlertTitle>Error</AlertTitle>
        {err.code} - {err.message}
        </Alert>, document.getElementById("alertContainer"));
        })
      }
  }

    const publishComment = () => {

      const axiosPosts = []
      var arrayOrders = []

      function findUnique(arr, predicate) {
        var found = {};
        arr.forEach(d => {
          found[predicate(d)] = d;
        });
        return Object.keys(found).map(key => found[key]); 
      }

      for (let i = 0; i < selectionModel.length; i++) {
          arrayOrders.push({"entity_id": selectionModel[i].entity_id})
      }

      var uniqueOrders = findUnique(arrayOrders, d => d.entity_id);

      console.log(uniqueOrders)

        if(valueComment.length === 0 || selectionModel.length == 0){
          ReactDOM.render(<Alert severity="warning" sx={{ width: '98%' }}>
            <AlertTitle>Warning</AlertTitle>
            You have to add some text and/or select at least one row!
        </Alert>, document.getElementById("alertContainer"));
      }
      else {

        var visibility = ""
        var notification = ""

        if(checkedVisible){visibility = "1"}else{visibility = "0"}

        if(checkedNotify){notification = "1"}else{notification = "0"}

        for (let i = 0; i < uniqueOrders.length; i++) {

          const dataComment = { "entity_id": uniqueOrders[i].entity_id, "statusHistory": {"comment": valueComment, "entity_id": 0,"entity_name": "order","status":orderStatus,"extension_attributes":{"added_by":username},"is_customer_notified": notification,"is_visible_on_front": visibility,"parent_id": uniqueOrders[i].entity_id}};
          
          const postComment = axios.post(configData.MAGENTO_APIS.MAGENTO_COMMENTS, dataComment);

          axiosPosts.push(postComment)

        }

        axios.all([axiosPosts])
        .then(ReactDOM.render(<LinearProgress />, document.getElementById("alertContainer")))
        .then(axios.spread((...responses) => {
        ReactDOM.render(<Alert severity="success" sx={{ width: '98%' }}>
        Comments posted!
        </Alert>, document.getElementById("alertContainer"));
        setTimeout(() => { updateRecords()}, 3000)
        }))
        .catch((err) => {
        console.log("AXIOS ERROR: ", err);
        ReactDOM.render(<Alert severity="error" sx={{ width: '98%' }}>
            <AlertTitle>Error</AlertTitle>
        {err.code} - {err.message}
        </Alert>, document.getElementById("alertContainer"));
        })
      }
    };

    // Orders via Euroest.ro API
    const sendEuroestOrder = () => {

        //const alertBar = document.getElementById(order_id);

        var entity_id = props.entity_id;

        const axiosPosts = []

        let token = document.head.querySelector('meta[name="csrf-token"]');

        if (token) {
            axios.defaults.headers.common['X-CSRF-TOKEN'] = token.content;
        } else {
            console.error('CSRF token not found: https://laravel.com/docs/csrf#csrf-x-csrf-token');
        }
        const products = [];

        for (let i = 0; i < selectionModel.length; i++) {
            var productNumber = trimBrand(selectionModel[i].name);
            var productManufacturer = selectionModel[i].name.split('_');
            var productQty = selectionModel[i].qty;

            products.push({"itemcode":productNumber, "quantity":productQty, "brand": productManufacturer[0]})
          }

        var orderBody = {
            "order":products,
            "remarks": "Order via API",
            "customercode": ""
        };

        

        if(selectionModel.length === 0){
            ReactDOM.render(<Alert severity="warning" sx={{ width: '98%' }}>
                <AlertTitle>Warning</AlertTitle>
                Please, select some products from the list!
              </Alert>, document.getElementById("alertContainer"))
        }
        else{
          console.log(orderBody);

        axios.post(configData.EXTERNAL_APIS.EUROEST_PLACE_ORDER, orderBody)
        .then(ReactDOM.render(<LinearProgress />, document.getElementById("alertContainer")))
        .then((res) => {
          var todayDateComment = new Date().toJSON().slice(0,10).replace(/-/g,'/');

          var orderedItems = res.data.orderlines;

          var itemsOrdered = ""

          for( let i = 0; i < orderedItems.length; i++) {
            itemsOrdered += orderedItems[i].itemcode
            itemsOrdered += " - "
            itemsOrdered += orderedItems[i].quantity
            itemsOrdered += " / "
          }

          //const dataComment = { "entity_id": entity_id, "statusHistory": {"comment": "Поръчката е изпратена към Euroest. Поръчка номер:"+res.data.orderno+" | Пръчяни артикули:"+orderedItems, "status": "processing","entity_id": 0,"entity_name": "order","is_customer_notified": 0,"is_visible_on_front": 0,"parent_id": entity_id}};
        
          const dataComment = {"order_id":order_id, "entity_id": entity_id, "statusHistory": {"comment": "Поръчката е изпратена към Euroest. Поръчка номер:"+res.data.orderno+" | Поръчани артикули:"+itemsOrdered, "status": "processing","entity_id": 0,"entity_name": "order","is_customer_notified": 0,"is_visible_on_front": 0,"parent_id": entity_id}};
        

          for (let i = 0; i < selectionModel.length; i++) {
            const dataComment = { "order_id": selectionModel[i].increment_id, "item_id": selectionModel[i].id, "entity_id": entity_id, "statusHistory": {"comment": "Поръчано към Euroest API на "+todayDateComment+" от "+userEmail, "status": "processing", "entity_id": 0,"entity_name": "order","is_customer_notified": 0,"is_visible_on_front": 0,"parent_id": entity_id}};

            const postComment = axios.post(configData.MAGENTO_APIS.MAGENTO_ORDER_MESSAGES_POST, dataComment);

            axiosPosts.push(postComment)

          }

          axios.all(axiosPosts)
          .then(ReactDOM.render(<LinearProgress />, document.getElementById("alertContainer")))
          .then(axios.spread((...responses) => {

            for( var y = 0; y < responses.length; y++ ) {
              console.log("RESPONSE RECEIVED: ", responses[y]);
            }
    
            ReactDOM.render(<Alert severity="success" sx={{ width: '98%' }}>

            Поръчка номер: {res.data.orderno} Поръчани артикули: {itemsOrdered}
            </Alert>, document.getElementById("alertContainer"));
          setTimeout(() => { updateRecords()}, 3000)
            // when the component is mounted, the alert is displayed for 3 seconds
        }))
        .catch((err) => {
        console.log("AXIOS ERROR: ", err);
        ReactDOM.render(<Alert severity="error" sx={{ width: '98%' }}>
            <AlertTitle>Error</AlertTitle>
        {err.code} - {err.message}
        </Alert>, document.getElementById("alertContainer"));
        })
    })}}

    // Function for sending orders via email - Darucar, Autobavaria, SilverStar
    const markExternalOrder = () => {

      console.log(selectionModel.length);

      var order_id = props.order_id;
      var entity_id = props.entity_id;
      var kh_code = props.customer;
      var branch = props.branch;

      const axiosPosts = []

      if(selectionModel.length === 0){
        ReactDOM.render(<Alert severity="warning" sx={{ width: '98%' }}>
            <AlertTitle>{configData.STRINGS.MESSAGE.WARNING_TITLE}</AlertTitle>
            {configData.STRINGS.MESSAGE.NO_SELECTED_PRODUCTS}
          </Alert>, document.getElementById("alertContainer"));
      }
      else{
        //setLoading(true);

      var todayDateComment = new Date().toJSON().slice(0,10).replace(/-/g,'/');

      for (let i = 0; i < selectionModel.length; i++) {
        const dataComment = { "order_id": selectionModel[i].increment_id, "item_id": selectionModel[i].id, "type":"external", "entity_id": entity_id, "statusHistory": {"comment": "Поръчано към доставчик извън dashboard на "+todayDateComment+" от "+userEmail, "status": "processing", "entity_id": 0,"entity_name": "order","is_customer_notified": 0,"is_visible_on_front": 0,"parent_id": entity_id}};

        const postComment = axios.post(configData.MAGENTO_APIS.MAGENTO_ORDER_MESSAGES_POST, dataComment);

        axiosPosts.push(postComment)

      }

      axios.all(axiosPosts)
      .then(ReactDOM.render(<LinearProgress />, document.getElementById("alertContainer")))
      .then(axios.spread((...responses) => {

        for( var y = 0; y < responses.length; y++ ) {
          console.log("RESPONSE RECEIVED: ", responses[y]);
        }

      ReactDOM.render(<Alert severity="success" sx={{ width: '98%' }}>
        <AlertTitle>Success</AlertTitle>
      </Alert>, document.getElementById("alertContainer"));
      setTimeout(() => { updateRecords()}, 3000)
        // when the component is mounted, the alert is displayed for 3 seconds
      }))
      .catch((err) => {
      console.log("AXIOS ERROR: ", err);
      ReactDOM.render(<Alert severity="error" sx={{ width: '98%' }}>
          <AlertTitle>Error</AlertTitle>
      0
      </Alert>, document.getElementById("alertContainer"));
      })
  }
  }

    // Function for sending orders via email - Darucar, Autobavaria, SilverStar
    const sendEmailOrders = (email, lang) => {

      console.log(selectionModel.length);

      var order_id = props.order_id;
      var entity_id = props.entity_id;
      var kh_code = props.customer;
      var branch = props.branch;

      const axiosPosts = []

      if(selectionModel.length === 0){
        ReactDOM.render(<Alert severity="warning" sx={{ width: '98%' }}>
            <AlertTitle>{configData.STRINGS.MESSAGE.WARNING_TITLE}</AlertTitle>
            {configData.STRINGS.MESSAGE.NO_SELECTED_PRODUCTS}
          </Alert>, document.getElementById("alertContainer"));
      }
      else{
        //setLoading(true);
        
      var text = '<b>Автоматично генерирана поръчка от Интер Карс България!</b><br><br>';
      var text_en = '<b>Automatically generated order from Inter Cars Bulgaria!</b><br><br>';
      var textInternal = '<b>Автоматично генерирана поръчка от Интер Карс България!</b><br><br>';

      for (let i = 0; i < selectionModel.length; i++) {
          text += trimBrand(selectionModel[i].name) + " - " + selectionModel[i].qty + " бр. ("+selectionModel[i].increment_id+")<br>";
          text_en += trimBrand(selectionModel[i].name) + " - " + selectionModel[i].qty + " qty ("+selectionModel[i].increment_id+")<br>";
          textInternal += trimBrand(selectionModel[i].name) + " - " + selectionModel[i].qty + " бр. - "+ selectionModel[i].price + " / Order id: "+selectionModel[i].increment_id+" ; Customer: "+selectionModel[i].branch+"<br>";

        }

        text += "<br><b>Моля за обратна връзка, в случай, че някой продукт не може да бъде доставен.</b><br>";
        text_en += "<br><b>Please, give us feedback if some product/s cannot be delivered or the price is different.</b><br>";
        text += "<br>При възникнали допълнителни въпроси, моля да се свържете с отдел Оригинални части (BGHQOEEmployees@intercars.eu)<br>";
        text_en += "<br>If you have any additional comments, you can contact us OE Spareparts department (BGHQOEEmployees@intercars.eu)<br>";
        text += "<br><b>Това е автоматично генериран имейл! Моля, не отговаряйте на него.</b><br>";
        textInternal += "<br><b>Това е автоматично генериран имейл! Моля, не отговаряйте на него.</b><br>";

      if(lang == "bg") {
        var data = {email:{receiver:email, subject: 'Поръчка - Inter Cars Bulgaria', message: text}}
      }
      else if(lang == "en"){
        var data = {email:{receiver:email, subject: 'Order - Inter Cars Bulgaria', message: text_en}}
      }
        

      const dataInternal = {email:{receiver:configData.SUPLIERS_EMAILS.INTERNAL_EMAIL, subject: 'Поръчка - Inter Cars Bulgaria', message: textInternal}}

      var todayDateComment = new Date().toJSON().slice(0,10).replace(/-/g,'/');

      var todayDate = new Date().toJSON().slice(0,10).replace(/-/g,'/');

      //const dataComment = { "entity_id": entity_id, "statusHistory": {"comment": "Вътрешен коментар! :Поръчано към доставчик "+email+" по имейл на "+todayDateComment, "status": "processing", "entity_id": 0,"entity_name": "order","is_customer_notified": 0,"is_visible_on_front": 0,"parent_id": entity_id}};
      
      //const dataComment = { "order_id":order_id, "entity_id": entity_id, "statusHistory": {"comment": "Поръчано към доставчик "+email+" по имейл на "+todayDateComment, "status": "processing", "entity_id": 0,"entity_name": "order","is_customer_notified": 0,"is_visible_on_front": 0,"parent_id": entity_id}};
      
      //console.log(entity_id);

      const externalEmail = axios.post(configData.EXTERNAL_APIS.EMAIL_SERVICE_SEND, data);
      const internalEmail = axios.post(configData.EXTERNAL_APIS.EMAIL_SERVICE_SEND, dataInternal);
      //const postComment = axios.post(configData.MAGENTO_APIS.MAGENTO_ORDER_MESSAGES_POST, dataComment);

      axiosPosts.push(externalEmail)
      axiosPosts.push(internalEmail)

      for (let i = 0; i < selectionModel.length; i++) {
        const dataComment = { "order_id": selectionModel[i].increment_id, "item_id": selectionModel[i].id,"type":"internal", "entity_id": entity_id, "statusHistory": {"comment": "Поръчано към доставчик "+email+" по имейл на "+todayDateComment+" от "+userEmail, "status": "processing", "entity_id": 0,"entity_name": "order","is_customer_notified": 0,"is_visible_on_front": 0,"parent_id": entity_id}};

        const postComment = axios.post(configData.MAGENTO_APIS.MAGENTO_ORDER_MESSAGES_POST, dataComment);

        axiosPosts.push(postComment)

      }

      axios.all(axiosPosts)
      .then(ReactDOM.render(<LinearProgress />, document.getElementById("alertContainer")))
      .then(axios.spread((...responses) => {

        for( var y = 0; y < responses.length; y++ ) {
          console.log("RESPONSE RECEIVED: ", responses[y]);
        }

      ReactDOM.render(<Alert severity="success" sx={{ width: '98%' }}>
        <AlertTitle>Success</AlertTitle>
      </Alert>, document.getElementById("alertContainer"));
      setTimeout(() => { updateRecords()}, 3000)
        // when the component is mounted, the alert is displayed for 3 seconds
      }))
      .catch((err) => {
      console.log("AXIOS ERROR: ", err);
      ReactDOM.render(<Alert severity="error" sx={{ width: '98%' }}>
          <AlertTitle>Error</AlertTitle>
      0
      </Alert>, document.getElementById("alertContainer"));
      })
  }
  }

    function calcDeliveryTime(brand, orderDate) {
      var dateOrder = new Date(orderDate);
      var today = Date.now();

      var diffDays = Math.round((today - dateOrder) / (1000 * 60 * 60 * 24))


      switch(brand) {
        case 'BMW': // 4 days
          if(diffDays > 4){
            return diffDays - 4;
          }
          else{
            return ""
          }
        case 'VAG': // 3 days
          if(diffDays > 3){
            return diffDays - 3;
          }
          else{
            return ""
          }
        case 'MB': // 5 days
          if(diffDays > 5){
            return diffDays - 5;
          }
          else{
            return ""
          }
        case 'PORSCHE': // 10-15 days
          if(diffDays > 10){
            return diffDays - 10;
          }
          else{
            return ""
          }
        default: // 5 days
          if(diffDays > 7){
            return diffDays - 7;
          }
          else{
            return ""
          }
      }
    }

    //console.log(rowcomments)


    function DetailPanelContent({ row: rowProp }) {
      return (
        <div>Test function</div>
      );
    }

    const getDetailPanelContent = React.useCallback(
      ({ row }) => <DetailPanelContent row={row} />,
      [],
    );

    const columns: GridColDef[] = [
        { field: 'increment_id', headerName: t('orders_table.column_order_id'), width: 140, renderCell: (ValueFormatterParams) => {
          const {row} = ValueFormatterParams;
          if(row.waiting_for_approval && row.status_histories.length > 0) {
            return (<span><HourglassBottomIcon fontSize="small" sx={{ color: red[500] }} /> {row.increment_id} <HtmlTooltip
            title={
              <React.Fragment>
                {row.status_histories.map((statusRow) => (
                        <TableRow key={statusRow.entity_id}>
                          <TableCell component="th" scope="row">
                          {statusRow.extension_attributes ? statusRow.extension_attributes.added_by : ""}
                          </TableCell>
                          <TableCell component="th" scope="row">
                          {statusRow.comment}
                          </TableCell>
                          <TableCell>{statusRow.created_at}</TableCell>
                        </TableRow>
    
                      ))}
              </React.Fragment>
            }
            >
            <img src="https://dev.bg.dashboard.vip-intercars.bg/chat.png"></img></HtmlTooltip></span>)
          }
          else if (row.waiting_for_approval && row.status_histories.length == 0) {
            return (<span><HourglassBottomIcon fontSize="small" sx={{ color: red[500] }} /> {row.increment_id}</span>)
          }

          if(row.status_histories.length > 0 && row.waiting_for_approval == false){
            return (
              <span>{row.increment_id} <HtmlTooltip
          title={
            <React.Fragment>
              {row.status_histories.map((statusRow) => (
                      <TableRow key={statusRow.entity_id}>
                        <TableCell component="th" scope="row">
                          {statusRow.extension_attributes ? statusRow.extension_attributes.added_by : ""}
                          </TableCell>
                        <TableCell component="th" scope="row">
                        {statusRow.comment}
                        </TableCell>
                        <TableCell>{statusRow.created_at}</TableCell>
                      </TableRow>
  
                    ))}
            </React.Fragment>
          }
          >
          <img src="https://dev.bg.dashboard.vip-intercars.bg/chat.png"></img></HtmlTooltip></span>
            )
          }
          else {
            return ( <span>{row.increment_id}</span> )
          }
          
        
        } },
        { field: 'waiting_for_approval', headerName: t('orders_table.waiting_for_approval'), width: 130, hide: true },
        { field: 'created_at', headerName: t('orders_table.column_date'), width: 130 },
        { field: 'id', headerName: t('orders_table.column_item_id'), width: 70 , hide: true},
        { field: 'branch', headerName: t('orders_table.column_branch_customer'), width: 120 },
        { field: 'status', headerName: t('orders_table.column_status'), width: 120, renderCell:  function(params) {
          if(params.row.status === "complete"){
            return <span className='badgeComplete'>{t('orders_table.order_status.complete')}</span>
          }
          else if(params.row.status === "processing"){
            return <span className='badgeProcessing'>{t('orders_table.order_status.processing')}</span>
          }
          else if(params.row.status === "canceled"){
            return <span className='badgeCanceled'>{t('orders_table.order_status.canceled')}</span>
          }
        }},
        { field: 'delay', headerName: t('orders_table.column_delay'), width: 70  },
        { field: 'brand', headerName: t('orders_table.column_brand'), width: 150 },
        { field: 'name', headerName: t('orders_table.column_product'), width: 250 , renderCell: function(params) {
          return(
          <span>{params.row.name} <Tooltip 
          title={params.row.external_id} ><NumbersIcon fontSize="small" sx={{ color: orange[500] }} /></Tooltip></span>)
          }},
        { field: 'qty', headerName: t('orders_table.column_qty'), width: 70 },
        { field: 'price', headerName: t('orders_table.column_price'), width: 100 },
        { field: 'total_price', headerName: t('orders_table.column_total_price'), width: 120 },
        { field: "action",
          headerName: t('orders_table.column_availability'),
          width: 100,
          editable: true,
          sortable: false,
          renderCell: (params) => {
      
            const onClickCheckAvailability = (e) => {
              e.stopPropagation(); // don't select this row after clicking
              
              setAlert(true)
              const api: GridApi = params.api;
              const thisRow: Record<string, GridCellValue> = {};
              //const rowId: Record<int, GridRowId> = {};
      
              api
                .getAllColumns()
                .filter((c) => c.field !== "__check__" && !!c)
                .forEach(
                  (c) => (thisRow[c.field] = params.getValue(params.id, c.field))
                );
    
              const clearProductMB = thisRow.name.replace("MB_",'')
      
      
              const euroEstApi = axios.get(configData.EXTERNAL_APIS.EUROEST_PRODUCT+trimBrand(clearProductMB), {timeout: 10000 });
              const ronaxAPI = axios.get(configData.EXTERNAL_APIS.RONAX_PRODUCT+trimBrand(clearProductMB), {timeout: 10000 })
              const autobavariaApi = axios.get(configData.EXTERNAL_APIS.AUTOBAVARIA_PRODUCT+trimBrand(clearProductMB), {timeout: 10000 })
              const darucarApi = axios.get(configData.EXTERNAL_APIS.DARUCAR_PRODUCT+trimBrand(clearProductMB), {timeout: 10000 })
    
              const prdNumber = thisRow.name;
              
              const brandProduct = prdNumber.substring(0, prdNumber.indexOf('_'));
    
      
                axios.all([euroEstApi, ronaxAPI, autobavariaApi, darucarApi])
                .then(ReactDOM.render(<LinearProgress />, document.getElementById("alertContainer")))
                .then(axios.spread((...responses) => {
                //console.log("RESPONSE RECEIVED: ", responses[0]);
                //console.log("RESPONSE RECEIVED: ", responses[1]);
                //console.log("RESPONSE RECEIVED: ", responses[2]);
      
                var messagesApis = [];
    
                if(responses[0].data.length > 0)
                                {
                                //var messageEuroest = '';
                                for(var i = 0; i < responses[0].data.length; i++) {
                                  messagesApis.push('<b>Euroest</b>: Number: '+responses[0].data[i].manufacturer+' '+responses[0].data[i].code+' | Price '+responses[0].data[i].price*configData.MAIN_CONFIGURATIONS.EURO_RATE+' BGN | Delivery: '+responses[0].data[i].delivery+' | Stock: '+responses[0].data[i].stock+' | <a href="">Buy</a>');
                                }
                              } else {
                                messagesApis.push('<b>Euroest</b>: '+trimBrand(thisRow.name));
                              }
                  
                              if(responses[1].data.length > 0)
                              {
                                //var messageRonax = '';
                                for(var y = 0; y < responses[1].data.length; y++) {
                                  messagesApis.push('<b>Ronax</b>: Number: '+responses[1].data[y].ProductName+' '+responses[1].data[y].PartNr+' | Price '+Number(responses[1].data[y].Price)*configData.MAIN_CONFIGURATIONS.EURO_RATE+' BGN | isOld: '+responses[1].data[y].isOld+' | <a href="">Buy</a>');
                                }
                              } else {
                                messagesApis.push('<b>Ronax</b>: '+trimBrand(thisRow.name));
                              }
    
                              if(responses[2].data.length > 0)
                              {
                                for(var x = 0; x < responses[2].data.length; x++) {
                                  messagesApis.push('<b>Autobavaria</b>: Number: '+responses[2].data[x].artikul_nomer+' | Description: '+responses[2].data[x].artikul_ime+' | Price '+Number(responses[2].data[x].price_lv_sdds)+' BGN | Stock: '+responses[2].data[x].kol+' | Rabat: '+responses[2].data[x].rabatna_grupa+' | <a href="">Buy</a>');
                                }
                                } else {
                                messagesApis.push('<b>Autobavaria</b>: '+trimBrand(thisRow.name));
                              }
    
                              if(responses[3].data.length > 0)
                              {
                                for(var t = 0; t < responses[3].data.length; t++) {
                                  messagesApis.push('<b>Darucar</b>: Number: '+responses[3].data[t].number+' | Description: '+responses[3].data[t].description+' | Price '+responses[3].data[t].price+' BGN | Stock: '+responses[3].data[t].stock+' | <a href="">Buy</a>');
                                }
                                } else {
                                messagesApis.push('<b>Darucar</b>: '+trimBrand(thisRow.name));
                          }
      
                  let textMessages = messagesApis.join(" <br> ");
                  
                  ReactDOM.render(
                    <Alert action={
                      <IconButton
                        aria-label="close"
                        color="inherit"
                        size="small"
                        onClick={() => {
                          setAlert(false);
                        }}
                      >
                        <CloseIcon fontSize="inherit" />
                      </IconButton>
                    } severity="success" sx={{ width: '98%' }}>
                    <div dangerouslySetInnerHTML={{__html: textMessages}} />
                    </Alert>, document.getElementById("alertContainer"));
                    
                    }))
                .catch((err) => {
                console.log("AXIOS ERROR: ", err);
                ReactDOM.render(<Alert severity="error" sx={{ width: '98%' }}>
                    <AlertTitle>Error</AlertTitle>
                {err.code} - {err.message}<br></br>
                </Alert>, document.getElementById("alertContainer"));
                })
            };
      
            
              return <React.Fragment><IconButton aria-label="Check price and stock" size="medium" color="success" onClick={onClickCheckAvailability}><SyncOutlinedIcon/></IconButton></React.Fragment>;
            
          }},
          { field: 'messages', headerName: t('orders_table.column_notes'), flex: 1, renderCell: (ValueFormatterParams) => {
            const {row} = ValueFormatterParams;

            if(row.messages.length > 0){
              return (
              <span>
              {row.messages.map((messageRow) => (
                <Tooltip title={messageRow.message} placement="left">
                  {messageRow.type == "internal" ? (<Chip color="warning" size="small" label="Поръчан" />) : (<Chip color="primary" size="small" label="Поръчан" />)}
                </Tooltip>

              ))}
              </span>
              )
            } else { return (<span></span>) }

          }
          }
      ];

    if (isLoading) {
        return <Container maxWidth="lg" className="loader"><CircularProgress /></Container>
      }
        return (
          <div style={{ height: '100%' }}>
            <div class="containerHeader">
              <img class="headerLogo" src="https://bg.marketplace.intercars.eu/media/logo/default/logo_Marketplace.jpg"></img>{isLoadingData ? <CircularProgress /> : ""}
              <ButtonGroup className='datePickerGroup' size="small" variant="text" aria-label="small button group">
                <Button size="small" onClick={() => changePeriod(0)}>{t('range.today')}</Button>
                <Button size="small" onClick={() => changePeriod(7)}>{t('range.last_seven_days')}</Button>
                <Button size="small" onClick={() => changePeriod(30)}>{t('range.last_thirty_days')}</Button>
              </ButtonGroup>
              <Divider variant="middle" />
            </div>
            <div class="containerNavigation">
            <Stack direction="row" divider={<Divider orientation="vertical" flexItem />} spacing={2}>
                <Stack direction="row" alignItems="center" spacing={1}>
                  <p className='ManageSectionTitle'>{t('manage_orders.manage_title')}</p>
                  <ButtonGroup variant="text" aria-label="text button group">
                  <IconButton aria-label="Confirm order" color="warning" size="medium" onClick={() => orderManage(1)}>
                    <CheckIcon fontSize="inherit" />
                  </IconButton>
                  <IconButton aria-label="Complete order" color="success" size="medium" onClick={() => orderManage(2)}>
                    <CheckCircleIcon fontSize="inherit" />
                  </IconButton>
                  <IconButton aria-label="Cancel order" color="error" size="medium" onClick={() => orderManage(0)}>
                    <CloseIcon fontSize="inherit" />
                  </IconButton>
                  </ButtonGroup>
                </Stack>
                <Stack direction="row" alignItems="center" spacing={1}>
                  <p className='ManageSectionTitle'>{t('manage_orders.suppliers_title')}</p>
                  {configData.MAIN_CONFIGURATIONS.BUTTON1_ENABLED ? <Button variant="outlined" onClick={() => sendEmailOrders(configData.SUPLIERS_EMAILS.SUPLIER_BMW_1, "bg")} size="small" endIcon={<SendIcon />}>
                    BMW DK
                  </Button>: ""}
                  {configData.MAIN_CONFIGURATIONS.BUTTON2_ENABLED ?<Button variant="outlined" onClick={() => sendEmailOrders(configData.SUPLIERS_EMAILS.SUPLIER_BMW_2, "bg")} size="small" endIcon={<SendIcon />}>
                    BMW AB
                  </Button>:""}
                  {configData.MAIN_CONFIGURATIONS.BUTTON3_ENABLED ?<Button variant="outlined" onClick={() => sendEmailOrders(configData.SUPLIERS_EMAILS.SUPLIER_MERCEDES_1, "bg")} size="small" endIcon={<SendIcon />}>
                    MERCEDES
                  </Button>:""}
                  {configData.MAIN_CONFIGURATIONS.BUTTON4_ENABLED ?<Button variant="outlined" onClick={() => sendEmailOrders(configData.SUPLIERS_EMAILS.SUPLIER_PORSCHE_1, "bg")} size="small" endIcon={<SendIcon />}>
                    Porsche
                  </Button>:""}
                  {configData.MAIN_CONFIGURATIONS.BUTTON5_ENABLED ?<Button variant="outlined" onClick={() => sendEmailOrders(configData.SUPLIERS_EMAILS.SUPLIERS_BUTTON_5, "en")} size="small" endIcon={<SendIcon />}>
                    Tiriac Auto
                  </Button>:""}
                  {configData.MAIN_CONFIGURATIONS.BUTTON6_ENABLED ?<Button variant="outlined" onClick={() => sendEmailOrders(configData.SUPLIERS_EMAILS.TEST_EMAIL, "bg")} size="small" endIcon={<SendIcon />}>
                    TEST BG
                  </Button>:""}
                  {configData.MAIN_CONFIGURATIONS.BUTTON7_ENABLED ?<Button variant="outlined" onClick={() => sendEmailOrders(configData.SUPLIERS_EMAILS.SUPLIERS_BUTTON_7)} size="small" endIcon={<SendIcon />}>
                    Porsche
                  </Button>:""}
                  {configData.MAIN_CONFIGURATIONS.EUROEST_ENABLED ?<Button variant="outlined" onClick={() => sendEuroestOrder()} size="small" endIcon={<SendIcon />}>
                    Euroest
                  </Button>:""}
                  {configData.MAIN_CONFIGURATIONS.RONAX_ENABLED ?<Button variant="outlined" size="small" disabled="true" endIcon={<SendIcon />}>
                    Ronax
                  </Button>:""}
                  {configData.MAIN_CONFIGURATIONS.EXTERNALBUTTON_ENABLED ?<Button variant="outlined" onClick={() => markExternalOrder()} size="small" endIcon={<SendIcon />}>
                    External
                  </Button>:""}
                </Stack>
              </Stack>
              <Divider variant="middle" />
              <Stack direction="row" divider={<Divider orientation="vertical" flexItem />} spacing={2}>
                <Stack direction="row" alignItems="center" spacing={1}>
                  <p className='ManageSectionTitle'>{t('manage_orders.comment_title')}</p>
                  <TextField label={t('manage_orders.comment_text_placeholder')} value={valueComment} onChange={handleChangeComment} id="outlined-size-small" size="small"/>
                  <FormControlLabel
                    value="end"
                    control={<Checkbox checked={checkedNotify} size="small"/>}
                    label={t('manage_orders.comment_notify_customer')}
                    labelPlacement="end"
                    onChange={handleChangeNotify}
                  />
                  <FormControlLabel
                    value="end"
                    control={<Checkbox checked={checkedVisible} size="small"/>}
                    label={t('manage_orders.comment_visible')}
                    labelPlacement="end"
                    onChange={handleChangeVisible}
                  />
                  <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                    <InputLabel id="demo-select-small">{t('orders_table.column_status')}</InputLabel>
                    <Select
                      labelId="select-status"
                      id="select-status"
                      value={orderStatus}
                      label={t('orders_table.column_status')}
                      onChange={handleChangeStatus}
                    >
                      <MenuItem value={'processing'}>{t('orders_table.order_status.processing')}</MenuItem>
                      <MenuItem value={'complete'}>{t('orders_table.order_status.complete')}</MenuItem>
                      <MenuItem value={'canceled'}>{t('orders_table.order_status.canceled')}</MenuItem>
                    </Select>
                  </FormControl>
                  <Button variant="contained" size="small" onClick={() => publishComment()}>
                  {t('manage_orders.comment_button_add')}
                </Button>
                </Stack>
              </Stack>
              
              <Divider variant="middle" />
            </div>
            <div id="alertContainerMain" class="containerContent"><Collapse in={alert}><div id="alertContainer"></div></Collapse></div>
            <div class="containerContent">
              {skeleton ? (
                <Stack spacing={1}>
                  <Skeleton variant="rectangular" height={10} />
                  <Skeleton variant="rectangular" height={5} />
                  <Skeleton variant="rectangular" height={10} />
                  <Skeleton variant="rectangular" height={10} />
                  <Skeleton variant="rectangular" height={5} />
                  <Skeleton variant="rectangular" height={10} />
                  <Skeleton variant="rectangular" height={10} />
                  <Skeleton variant="rectangular" height={5} />
                  <Skeleton variant="rectangular" height={10} />
                  <Skeleton variant="rectangular" height={10} />
                </Stack>
              ) : (
              <DataGrid
              rows={items}
              columns={columns}
              rowHeight={50}
              autoHeight={true}
              components={{
                Toolbar: CustomToolbar,
              }}
              checkboxSelection
              disableSelectionOnClick
              getTreeDataPath={({ row }) => <div>Row ID: {row.id}</div>}
              getDetailPanelContent={getDetailPanelContent}
              experimentalFeatures={{ newEditingApi: true }}
              localeText={gridLocalization}
              onSelectionModelChange={(ids) => {
                const selectedIDs = new Set(ids);
                const selectedRowData = items.filter((row) =>
                  selectedIDs.has(row.id)
                );
                setSelectionModel(selectedRowData)
             
              }}
              getRowClassName={(params) => {
                if(params.row.status === "canceled") {
                  return params.row.status === "canceled" ? "orderComplete" : "";
                }
                else if((params.row.status === "processing")) {
                  if((params.row.delay !== "")) {
                    return params.row.delay !== "" ? "orderDelay" : "";
                  }
                  else if((params.row.approval_info[0].approved !== "no")) {
                    return params.row.approval_info[0].approved !== "no" ? "orderApproved" : "";
                  }
                }
                
              }}
            />
            )}
            </div>
        </div>
        );

    
}